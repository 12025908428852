import { memo, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import sortBy from 'lodash/sortBy';
import { useI18n } from '@src/locales';
import { generateUrl, getCategoryTitle, slugify } from '@src/utils';
import * as Styles from './allcategories.styled';

const AllCategories = ({ submenu }) => {
    const { locale } = useRouter();
    const { t } = useI18n();

    const allProducts = submenu.find((item) => item.id === process.env.NEXT_PUBLIC_ALL_PRODUCTS_ID);
    const ourCollection = allProducts?.sub_segments || [];

    const sortOurCollection = useMemo(() => sortBy(
        ourCollection,
        [(segment) => getCategoryTitle(segment, locale, true)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [locale]);

    return (
        <div>
            <Styles.Title>{t('discoverOurCategories')}</Styles.Title>
            <Styles.Allcategories>
                {sortOurCollection.map((segment) => {
                    const title = getCategoryTitle(segment, locale);

                    return (
                        <div key={segment.id} className={segment.segment_title}>
                            <Link href={generateUrl({ levelOne: segment, locale })} passHref prefetch={false}>
                                <Styles.Link>
                                    <Image
                                        src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}layout/new/${slugify(segment.segment_title)}.png`}
                                        width={200}
                                        height={200}
                                        layout="intrinsic"
                                        quality={75}
                                        alt={title}
                                    />
                                    <h3 className="segment_title">{title}</h3>
                                </Styles.Link>
                            </Link>
                        </div>
                    );
                })}
            </Styles.Allcategories>
        </div>
    );
};

export default memo(AllCategories);
