import styled from 'styled-components';

export const Title = styled.h3`  
    font-size: 22px;
    font-weight: 700;
    margin: 45px 0 30px;
    text-align: left;
    color: var(--dark);

    @media (max-width: 767px) {
        font-size: 18px;
        margin: 49px 0 8px;
    }
`;

export const Link = styled.a`
    display: block;
    margin: 15px 0 25px;
    color: var(--dark);
    transition: color .15s ease-out;
    text-align:center;

    > span {
        position: relative;
        overflow: visible !important;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #f0f3f6;
            border-radius: 999em;
            z-index: 0;
        }
    }

    img {
        transition: all .3s ease-out;
        filter: drop-shadow(none);
        z-index: 1;
    }

    &:hover {
        color: var(--primary);

        img {
            transform: scale(1.2);
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.4));
        }   
    }

    h3 {
        margin: 11px 0 0;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        transition: all .5s ease;
        word-wrap: break-word;
    }

    @media (max-width: 767px) {
        margin: 15px 0 12px;

        div {
            height: 40px;
            background-size: 40px auto;
        }

        h3 {
            font-size: 12px;
            margin: 10px 0 0;
        }
    }
`;

export const Allcategories = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    > div {
        width: calc(20% - 1rem);
    }

    @media (max-width: 767px) {
        div {
            width: calc(33.3333% - 1rem);
        }
    }
`;
